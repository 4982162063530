import { Flex, Image, useTheme } from "@aws-amplify/ui-react";

export function Header() {
  const { tokens } = useTheme();

  return (
    <Flex justifyContent="center">
      <Image
        alt="logo"
        src="https://logindemo.nextlevel3.com/td-logo.png"
        padding={tokens.space.zero}
      />
    </Flex>
  );
}