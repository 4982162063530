import React from 'react';
import ReactDOM from 'react-dom';
import { StrictMode } from "react";
import { AmplifyProvider, Authenticator } from "@aws-amplify/ui-react";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './index.css';
import { Layout } from './Layout';
import { Home } from "./Home";
//import { RequireAuth } from './RequireAuth';
import { Login } from "./Login";
import reportWebVitals from './reportWebVitals';

const rootElement = document.getElementById("root");
ReactDOM.render(
  <AmplifyProvider>
    <Authenticator.Provider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={ <Home /> }/>
            <Route path="/login" element={<Login />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </Authenticator.Provider>
  </AmplifyProvider>,
  rootElement
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
