import React, { useState, useEffect } from "react";
import { Buffer } from "buffer";
import { useLocation, Navigate } from 'react-router-dom';
import { useAuthenticator,
    Flex,
    Grid,
    useTheme,    
    View } from "@aws-amplify/ui-react";
import { Footer } from "./Footer";    
import "./App.css";
import "./styles.css"
import { createTodo, createNl3Enabled, updateNl3Enabled, updateTodo } from "./graphql/mutations";
import { listNl3Enableds, listTodos } from "./graphql/queries";
import { API, graphqlOperation, GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { Amplify, Auth } from "aws-amplify";
import * as mutations from './graphql/mutations';
import awsExports from "./aws-exports";
import { UniqueDirectiveNamesRule } from "graphql";

Amplify.configure(awsExports);

export function Home() {
    //const { tokens } = useTheme();
    var { user } = useAuthenticator();
    const location = useLocation();
    const { authStatus } = useAuthenticator(context => [context.authStatus]);
    const [allTodos, setAlltodos] = useState(null);
    const [nl3Enabled, setNL3Enabled] = useState(null);
    const [idToken, setIdToken] = useState("");
    var sessionData;
    Auth.currentAuthenticatedUser().then( (result) => {
        user = result;
    });

    function isEmptyObj(obj) {
        for (var i in obj) return false;
        return true;
    }

    useEffect(() => {
        (async () => {
            //console.log('Inside Home useEffect');
            if(authStatus === 'authenticated') {
                user = await Auth.currentAuthenticatedUser();
                //console.log(JSON.stringify("User = " + user));
                //console.log('Inside Home useEffect & authenticated');                
                if (typeof user !== undefined && !isEmptyObj(user)) {
                    //console.log('Inside Home useEffect, authenticated, and user object defined');                
                    if ("username" in user) {
                        //console.log('Inside Home useEffect, authenticated, user object defined, and username key');                                     
                        await Auth.currentSession().then(data=>{
                            sessionData = data;
                                
                            //You can print them to see the full objects
                            //console.log(`myAccessToken: ${JSON.stringify(sessionData.accessToken)}`)
                            //console.log(`myIdToken: ${JSON.stringify(sessionData.idToken)}`)
                            setIdToken(Buffer.from(JSON.stringify(sessionData.idToken), 'utf-8').toString('Base64'));
                            //console.log(`myJwt: ${jwt}`)
                          });                        
                        const todos = await API.graphql(graphqlOperation(listTodos));
                        setAlltodos(todos.data.listTodos.items.sort());
                       
                        //console.log('Username = ' + user.username);
                        /*const listEnableds = await API.graphql(graphqlOperation(listNl3Enableds, { filter: { owner: { eq: user.username } } }));
                        if (listEnableds === undefined || listEnableds === null || listEnableds.data.listNl3Enableds.items.length === 0) {
                            const created = await API.graphql(graphqlOperation(createNl3Enabled, { input: { enabled: false, confirmed: false, nl3usertoken: "test" } }));    
                            setNL3Enabled(created.data.createNl3Enabled);
                        } else {
                            setNL3Enabled(listEnableds.data.listNl3Enableds.items[0]);
                        }*/                        
                    }
                } 
            }
        })();
    }, []);

    /*const getAllTodos = async () => {
        if (authStatus === "authenticated" && typeof user !== undefined && !isEmptyObj(user) && "username" in user) {
            const todos = await API.graphql(graphqlOperation(listTodos));
            setAlltodos(todos.data.listTodos.items);
            console.log('Username = ' + user.username);
            const listEnableds = await API.graphql(graphqlOperation(listNl3Enableds, { filter: { owner: { eq: user.username } } }));
            if (listEnableds === undefined || listEnableds === null || listEnableds.data.listNl3Enableds.items.length === 0) {
                const created = await API.graphql(graphqlOperation(createNl3Enabled, { input: { enabled: false, confirmed: false, nl3usertoken: "test" } }));    
                setNL3Enabled(created.data.createNl3Enabled);
            } else {
                setNL3Enabled(listEnableds.data.listNl3Enableds.items[0]);
            }
        }
    };*/

    /*const enableNL3 = async (e) => {
        e.preventDefault();
        if (!user.username || user.username.length === 0) return alert("Cannot determine username to enable!");        
        try {
            const apiName = "nl3authtoken";
            const path = "/token"
            const myInit = {
                queryStringParameters: {  
                    userName: user.username,
                }
            }
            const response = await API.get(apiName, path, myInit);
            //console.log(response);
            if (response["auth-token"].length > 0) {
                window.open("https://cloud.dev.nextlevel3.com?auth-token=" + response["auth-token"]);
                let updatedEnabled = { id: nl3Enabled.id, nl3usertoken: "test" };
                updatedEnabled.enabled = true;
                const updated = await API.graphql(graphqlOperation(updateNl3Enabled, { input: updatedEnabled }));
                //console.log(updated);
                setNL3Enabled(updated.data.updateNl3Enabled);
            }
        } catch (e) {
            console.log(e)
        }                
    };

    const confirmNL3 = async (e) => {
        e.preventDefault();
        let updatedEnabled = { id: nl3Enabled.id, nl3usertoken: nl3Enabled.nl3usertoken };
        updatedEnabled.enabled = true;
        updatedEnabled.confirmed = true;
        const updated = await API.graphql(graphqlOperation(updateNl3Enabled, { input: updatedEnabled }));
        //console.log(updated);
        setNL3Enabled(updated.data.updateNl3Enabled);
    };*/    

    async function getDeviceData() {
        try {
            let userAgent = "";
            if ("userAgent" in navigator) {
                userAgent = navigator.userAgent
            }     
            let deviceData = {};
            deviceData = await (await fetch("https://ipinfo.io/json?token=a35bb8e4c29a96")).json();
            //console.log(JSON.stringify(deviceData));
            //console.log(userAgent)
            if (deviceData===undefined) {
                deviceData["ip"] = "";
                deviceData["city"] = "";
                deviceData["region"] = "";
            } else {
                deviceData["userAgent"] = userAgent;
            }
            return deviceData;
        } catch (e) {
            console.log("Caught getDeviceData Error = " + e.message);
            return {};
        }
        // custom username
    } 

    async function getAuthToken() {
        try {
            const apiName = "nl3authtoken";
            const path = "/token"
            const myInit = {
                queryStringParameters: {  
                    userName: user.username,
                }
            }
            const response = await API.get(apiName, path, myInit);
            //console.log(response);
            if (response["auth-token"].length > 0) {
              return response["auth-token"];
            }            
            return {};
        } catch (e) {
            console.log("Caught getAuthToken error = " + e.message);
            return {};
        }
        // custom username
    }     

    async function waitForPopUp(approvalRequestSessionId, intervalInt, retryAttempts)
    {                   
        let { username } = user.username;    
        return new Promise(function(resolve, reject) {
            //console.log("Inside waitForPopUp function!");
            var autoAPIStatus;
            function receiveMessage(event) {
                console.log("Event origin = " + event.origin);
                if (event.origin !== "https://cloud.dev.nextlevel3.com") {
                    console.warn(`Message received by ${event.origin}; IGNORED.`);
                    return;
                }
                //console.log("Event data = " + event.data);
                autoAPIStatus = JSON.parse(event.data);
            }
            window.addEventListener("message", receiveMessage, false);             
                const features = {
                popup: "yes",
                width: 550,
                height: 550,
                top: "auto",
                left: "auto",
                toolbar: "no",
                menubar: "no",
            };
            
            const strWindowsFeatures = Object.entries(features)
            .reduce((str, [key, value]) => {
                if (value === "auto") {
                if (key === "top") {
                    const v = Math.round(
                    window.innerHeight / 2 - features.height / 2
                    );
                    str += `top=${v},`;
                } else if (key === "left") {
                    const v = Math.round(
                    window.innerWidth / 2 - features.width / 2
                    );
                    str += `left=${v},`;
                }
                return str;
                }
            
                str += `${key}=${value},`;
                return str;
            }, "")
            .slice(0, -1); // remove last ',' (comma)

            //console.log("Right before Popup!");
            var pop = window.open("https://cloud.dev.nextlevel3.com/autoApprove?uid=" + 
                encodeURIComponent(user.username) + 
                "&fqdn=" + encodeURIComponent("logindemo.nextlevel3.com") + 
                "&approvalRequestSessionId=" + encodeURIComponent(approvalRequestSessionId), 
                "_blank", strWindowsFeatures);            
        
            setInterval(function () {
                retryAttempts = retryAttempts - 1;
                if (retryAttempts <= 0)
                    reject("Unlock Failed!");
                if (pop.closed)
                  if (typeof autoAPIStatus !== 'undefined')
                    resolve(autoAPIStatus);
                  else
                    reject("Unlock Failed!");
            },
            intervalInt);
        });
    }    

    async function requestApprovalAutoApprove(todo, callbackFunction, changeDescription) {
        try {
            let authToken = await getAuthToken();
            //console.log("authToken = " + authToken);
            let deviceData = await getDeviceData();
            let headers = {
              "x-nl3-authorization-token": authToken, 
              "Content-Type": "application/json",
              "x-nl3-device-location": deviceData["loc"]
            };
              
            let payload = {
              "approvers": [ user.username ],
              "metaData": {
                "username": user.username,
                "taskName": todo.name,
                "changeDescription": changeDescription,
              },                
              //"userIP": deviceData["ip"],
              //"userDevice": deviceData["userAgent"],
              //"userLocation": deviceData["location"],
              //"integrationType": "cognito",
              //"integrationData": deviceData,
              "templateId": "change_task_approval"
            };
            //console.log(JSON.stringify(payload));
            const approvalEndpoint = 'https://api.dev.nextlevel3.com/nl3/api/v1/approvalRequest';
            const apiResponse = await fetch(approvalEndpoint, {
                method: 'POST',
                headers: headers,
                body: JSON.stringify(payload)
            }); 
            let apiResponseJSON = await apiResponse.json();
            //console.log(JSON.stringify(apiResponseJSON));
            //console.log(apiResponse.status);
            if (apiResponse !== undefined) {
                await waitForPopUp(apiResponseJSON.sessionId, 500, 240).then(async function (result) { 
                    if (result !== undefined && "approved" in result & result.approved){
                        if (callbackFunction == "toggleTodoCallback") {
                            toggleTodoCallback(todo);
                        } else {
                            submitAddTodoCallback(todo);
                        }
                    }
                  }).catch(async function (error) {
                    revertTodo(todo.id);
                    console.log("Caught requestApprovalAutoApprove promise Error = " + 
                    + error)
                  });
            }
        } catch (e) {
            revertTodo(todo.id);
            console.log("Caught requestApprovalAutoApprove Error = " + JSON.stringify(e));
        }
        // custom username
    }     

    const [name, setTodoName] = useState("");

    const changeTodoName = (e) => {
        setTodoName(e.target.value);
    };

    const submitAddTodoCallback = async (todo) => {
        try {
            var submitResponse = await API.graphql({
                query: mutations.createTodo, 
                variables: {input: todo}
                });                       
            allTodos === null ? setAlltodos([todo]) : setAlltodos([todo, ...allTodos]); 
        } catch (e) {
            console.log("Caught submitAddTodoCallback Error = " + JSON.stringify(e));
        }                    
    };

    const submitAddTodo = async (e) => {
        e.preventDefault();
        try {
            if (name === "") return alert("Input field cannot be empty");
            const todo = { name, done: false };
            requestApprovalAutoApprove(todo, 'submitAddTodoCallback', `Add new task named "${name}".`);
        } catch (e) {
            console.log("Caught submitAddTodo Error = " + JSON.stringify(e));
        }                    
    };
    

    const toggleTodoCallback = async (todo) => {
        try {
            var toggleResponse = await API.graphql({
                query: mutations.updateTodo, 
                variables: { input: todo }
            });    
        } catch (e) {
            revertTodo(todo.id);
            console.log("Caught toggleTodoCallback Error = " + JSON.stringify(e));
        }                    
    };

    const toggleTodo = async (id) => {
        try {
            const todo = allTodos.find(({ id: _id }) => _id === id);
            let newTodo = { id, name: todo.name };
            newTodo.done = todo.done ? false : true;
            requestApprovalAutoApprove(newTodo, 'toggleTodoCallback', `Change task completed status from "${todo.done.toString().toUpperCase()}" to "${newTodo.done.toString().toUpperCase()}."`);
        } catch (e) {
            console.log("Caught toggleTodo Error = " + JSON.stringify(e));
        }
    };

    const revertTodo = async (id) => {
        try {
            const prevTodo = allTodos.find(({ id: _id }) => _id === id);
            const filteredTodos = allTodos.filter(t => t.id !== prevTodo.id);
            allTodos === null ? setAlltodos([prevTodo]) : setAlltodos([prevTodo, ...filteredTodos]);
        } catch (e) {
            console.log("Caught toggleTodo Error = " + JSON.stringify(e));
        }
    };

    const TodoItem = ({number, id, name, done}) => (
        <Flex
            justifyContent="flex-start"
            alignItems="center"
            borderRadius="5px"
            fontWeight="bold"
        >
            <div className="todo-block" key={number}>          
                <input
                onClick={() => toggleTodo(id)}
                type="checkbox"
                id={id}
                value={id}
                key={number}
                defaultChecked={done}
                />
                <label htmlFor={id}>{name}</label>          
            </div>
        </Flex>
    );     

    if (authStatus !== 'authenticated') {
        console.log('Home executing, not authenticated, redirecting to /login!');
        return <Navigate to="/login" state={{ from: location }} replace={false} />;
    } else {
        return (        
            <Grid width="100%" templateColumns="1fr 1fr" templateRows="2.5rem 4rem auto 2rem">                                               
                <View columnSpan={2}>
                    <Flex alignItems="center" justifyContent="center" height="2.5rem" fontSize="large"  color="#fff" backgroundColor="#000">
                        <b>NL3 Demo ToDo App</b>
                    </Flex>
                </View>
                <View columnSpan={2} padding="1rem">
                    <Flex alignContent="center" justifyContent="center">                    
                        <form className="add-todo-form" onSubmit={submitAddTodo}>
                        <input
                            placeholder="Add Todo"
                            onChange={changeTodoName}
                        />
                        <button type="submit">+</button>
                        </form>          
                    </Flex>
                </View>
                <View columnSpan={2}>
                    <Flex justifyContent="space-around" margin="0 auto" maxWidth="25rem" alignContent="top">                                        
                        {allTodos === null ? (
                        <p>Loading Todos...</p>
                        ) /*&& await getAllTodos()*/ : allTodos.length === 0 ? (
                        <p>No Todo available</p>
                        ) : (
                        <div className="todos">
                            {allTodos.sort().map(({ id, name, done },i) => (
                            <TodoItem number={i} id={id} name={name} done={done} />
                            ))}
                        </div>
                        )}          
                    </Flex>
                </View>           
                <View columnSpan={2}>
                    <Footer/>        
                </View>            
            </Grid>
        );
    }    
}