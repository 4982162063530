/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getTodo = /* GraphQL */ `
  query GetTodo($id: ID!) {
    getTodo(id: $id) {
      id
      name
      done
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listTodos = /* GraphQL */ `
  query ListTodos(
    $filter: ModelTodoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTodos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        done
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getNl3Enabled = /* GraphQL */ `
  query GetNl3Enabled($id: ID!) {
    getNl3Enabled(id: $id) {
      id
      confirmed
      enabled
      nl3usertoken
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listNl3Enableds = /* GraphQL */ `
  query ListNl3Enableds(
    $filter: ModelNl3EnabledFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNl3Enableds(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        confirmed
        enabled
        nl3usertoken
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
