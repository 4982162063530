import React, { useEffect } from "react";
import { useLocation, Navigate, useNavigate } from 'react-router-dom';
import {
//    useTheme,
    useAuthenticator,
    Authenticator,
    Flex,
    Grid,
  } from "@aws-amplify/ui-react";
//import { tokens } from useTheme;
import { SignInHeader } from "./SignInHeader";
import { SignInFooter } from "./SignInFooter";
import { Footer } from "./Footer";    
import "./App.css";
import '@aws-amplify/ui-react/styles.css';
import { Amplify, Auth } from "aws-amplify";
import awsExports from "./aws-exports";
//import { updateExpressionWithTypeArguments } from "typescript";

Amplify.configure(awsExports);

export function Login() {
    const { authStatus } = useAuthenticator((context) => [context.authStatus]);
    const location = useLocation();
    const navigate = useNavigate();    
    const { signOut, user } = useAuthenticator();
    //const { authStatus } = useAuthenticator(context => [context.authStatus]);
    //let home = '/';
    let services;
    let from = location.state?.from?.pathname || '/';

    /*useEffect(() => {
      if (authStatus === 'authenticated') {
        console.log('Inside authenticate useEffect');
        navigate(from, { replace: true });
      }
    }, [authStatus, navigate, from]);*/

    const components = {
        SignIn: {
          Header: SignInHeader,
          Footer: SignInFooter
        },
        SignUp: {
          Header: SignInHeader,
          Footer: SignInFooter
        },    
        Footer
    }; 

    async function waitForPopUp(formData, intervalInt, retryAttempts)
    {                   
        let { username } = formData;    
        return new Promise(function(resolve, reject) {
            console.log("Inside waitForPopUp function!");
            var autoAPIStatus;
            function receiveMessage(event) {
                console.log("Event origin = " + event.origin);
                if (event.origin !== "https://cloud.dev.nextlevel3.com") {
                    console.warn(`Message received by ${event.origin}; IGNORED.`);
                    return;
                }
                console.log("Event data = " + event.data);
                autoAPIStatus = event.data;
            }
            window.addEventListener("message", receiveMessage, false);             
                const features = {
                popup: "yes",
                width: 550,
                height: 550,
                top: "auto",
                left: "auto",
                toolbar: "no",
                menubar: "no",
            };
            
            const strWindowsFeatures = Object.entries(features)
            .reduce((str, [key, value]) => {
                if (value === "auto") {
                if (key === "top") {
                    const v = Math.round(
                    window.innerHeight / 2 - features.height / 2
                    );
                    str += `top=${v},`;
                } else if (key === "left") {
                    const v = Math.round(
                    window.innerWidth / 2 - features.width / 2
                    );
                    str += `left=${v},`;
                }
                return str;
                }
            
                str += `${key}=${value},`;
                return str;
            }, "")
            .slice(0, -1); // remove last ',' (comma)

            console.log("Right before Popup!");
            var pop = window.open("https://cloud.dev.nextlevel3.com/autoUnlock?uid=" + encodeURIComponent(username) + "&fqdn=" + encodeURIComponent("logindemo.nextlevel3.com"), "_blank", strWindowsFeatures);            
        
            setInterval(function () {
                retryAttempts = retryAttempts - 1;
                if (retryAttempts <= 0)
                    reject();
                if (typeof autoAPIStatus !== 'undefined' || pop.closed)
                    resolve(formData);
            },
            intervalInt);
        });
    }

    async function executeSignIn(formData, deviceData) {
        let { username, password } = formData;
        try {
            return Auth.signIn({
                username,
                password,
                validationData: { 
                    ip: deviceData.ip,
                    device: deviceData.device,
                    location: deviceData.city + ", " + deviceData.region,
                    additionalData: JSON.stringify(deviceData),
                },
            });
        } catch (e) {
            console.log("Caught executeSignIn Error = " + e.message);
        }
        // custom username
    }

    async function getDeviceData() {
        try {
            let userAgent = "";
            if ("userAgent" in navigator) {
                userAgent = navigator.userAgent
            }     
            let deviceData = {};
            deviceData = await (await fetch("https://ipinfo.io/json?token=a35bb8e4c29a96")).json();
            console.log(JSON.stringify(deviceData));
            console.log(userAgent)
            if (deviceData===undefined) {
                deviceData["ip"] = "";
                deviceData["city"] = "";
                deviceData["region"] = "";
            }
            deviceData["device"] = userAgent;            
            return deviceData;
        } catch (e) {
            console.log("Caught executeSignIn Error = " + e.message);
        }
        // custom username
    }    
    
    services = {
        async handleSignIn(formData) {
            console.log("Inside handleSignIn");

            await waitForPopUp(formData, 500, 240).then(async function (result) { 
                let deviceData = await getDeviceData();
                console.log(JSON.stringify(deviceData));                 
                let {username, password} = result;
                return Auth.signIn({
                    username,
                    password,
                    validationData: { 
                        ip: deviceData.ip,
                        device: deviceData.device,
                        location: deviceData.city + ", " + deviceData.region,
                        additionalData: JSON.stringify(deviceData),
                    },                            
                });
            });

            /*var interval = window.setInterval(async function() {
            try {
                console.log('Inside setInterval');
                // get elem
                if (typeof autoAPIStatus == 'undefined' && !pop.closed) return;
                console.log("Calling clearInterval!");           
                clearInterval(interval); 
                await executeSignIn(formData, deviceData);
                window.location.replace("https://logindemo.nextlevel3.com");
            } catch (e) {
                console.log("Caught setInterval Error = " + e.message);
                window.location.replace("https://logindemo.nextlevel3.com");
            }
            }, 500); */
            // custom username
        },
        async handleSignUp(formData) {
            let { username, password, attributes } = formData;
            //console.log("Inside handleSignUp");
            // custom username
            username = username.toLowerCase();
            attributes.email = attributes.email.toLowerCase();
            return Auth.signUp({
                username,
                password,
                attributes,
                autoSignIn: {
                    enabled: false
                }
            });
        },      
        async handleConfirmSignUp(formData) {
            let { username, code } = formData;
            var interval2 = window.setInterval(async function() {
                let elements = document.getElementsByName('confirmation_code');
                if (typeof elements !== undefined && elements !== null && elements.length > 0 ) return;
                clearInterval(interval2);
                window.location.replace('/');
            }, 1000); 
            return Auth.confirmSignUp(username, code);
        },          
    };    

    return (        
          <>
            {
                authStatus === 'configuring' && 
                    <div>
                        <br/><br/><br/>
                        <button type="button" disabled>
                            Loading...
                        </button>
                    </div>
            }
            {
            authStatus !== 'authenticated' && 
                <Grid templateColumns={{ base: "1fr 0" }}>
                    <Flex
                    //backgroundColor={tokens.colors.background.secondary}
                    backgroundColor="#f8f6ef"
                    justifyContent="center"
                    >
                    <Authenticator services={services} components={components}>    
                        < Navigate to="/" replace={true} />
                    </Authenticator>
                    </Flex>
                </Grid>
            }
            {
                authStatus === 'authenticated' && 
                <Navigate to="/" replace={true} />
            }

          </>
        )
}