import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useAuthenticator, Button, View } from '@aws-amplify/ui-react';
import { Auth } from "aws-amplify";
import { Header } from "./Header";

export function Layout() {
  const { authStatus } = useAuthenticator((context) => [context.authStatus]);
  const navigate = useNavigate();

  async function logOut() {
    await Auth.signOut();
    navigate('/login', { replace: true });
    window.location.reload();
  }
  return (
    <>
      <nav>
        <View style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'row',
        }}>
            <View style={{
                flexBasis: 82,
                flexGrow: 0,
                flexShrink: 0,
            }}>
                <Button onClick={() => navigate('/')}>Home</Button>
            </View>
            <View style={{
                flexBasis: 101,
                flexGrow: 0,
                flexShrink: 0,
            }}>
                {authStatus !== 'authenticated' ? (
                <Button onClick={() => navigate('/login')}>Sign In</Button>
                ) : (
                <Button onClick={() => logOut()}>Sign Out</Button>
                )}
            </View>
            <View style={{
                flexBasis: 'auto',
                flexGrow: 1,
                flexShrink: 0,                
            }}>
                <Header />  
            </View>
            <View style={{
                flexBasis: 101,
                flexGrow: 0,
                flexShrink: 0,
            }}>
                <></>
            </View>
            <View style={{
                flexBasis: 87,
                flexGrow: 0,
                flexShrink: 0,
            }}>
                <></>
            </View>
        </ View>      
      </nav>
      <Outlet />
    </>
  );
}