/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createTodo = /* GraphQL */ `
  mutation CreateTodo(
    $input: CreateTodoInput!
    $condition: ModelTodoConditionInput
  ) {
    createTodo(input: $input, condition: $condition) {
      id
      name
      done
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateTodo = /* GraphQL */ `
  mutation UpdateTodo(
    $input: UpdateTodoInput!
    $condition: ModelTodoConditionInput
  ) {
    updateTodo(input: $input, condition: $condition) {
      id
      name
      done
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteTodo = /* GraphQL */ `
  mutation DeleteTodo(
    $input: DeleteTodoInput!
    $condition: ModelTodoConditionInput
  ) {
    deleteTodo(input: $input, condition: $condition) {
      id
      name
      done
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createNl3Enabled = /* GraphQL */ `
  mutation CreateNl3Enabled(
    $input: CreateNl3EnabledInput!
    $condition: ModelNl3EnabledConditionInput
  ) {
    createNl3Enabled(input: $input, condition: $condition) {
      id
      confirmed
      enabled
      nl3usertoken
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateNl3Enabled = /* GraphQL */ `
  mutation UpdateNl3Enabled(
    $input: UpdateNl3EnabledInput!
    $condition: ModelNl3EnabledConditionInput
  ) {
    updateNl3Enabled(input: $input, condition: $condition) {
      id
      confirmed
      enabled
      nl3usertoken
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteNl3Enabled = /* GraphQL */ `
  mutation DeleteNl3Enabled(
    $input: DeleteNl3EnabledInput!
    $condition: ModelNl3EnabledConditionInput
  ) {
    deleteNl3Enabled(input: $input, condition: $condition) {
      id
      confirmed
      enabled
      nl3usertoken
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
